@media (min-width: 768px) { /* Medium devices (tablets, 768px and up) */
    .card {
      width: 80%; /* Adjust card width for medium devices */
    }
  }
  
  @media (min-width: 992px) { /* Large devices (desktops, 992px and up) */
    .card {
      width: 90%; /* Adjust card width for large devices */
    }
  }
  
  @media (min-width: 1200px) { /* Extra large devices (large desktops, 1200px and up) */
    .card {
      width: 100%; /* Adjust card width for extra large devices */
    }
  }
  
  .card {
    /* Your existing card styles */
    /* Add any other necessary styles */
  
    /* Apply 3D effect */
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    transform-style: preserve-3d;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    
    
  }
  
  .card:hover {
    /* Scale up the card and adjust shadow on hover */
    transform: scale(1.05);
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
  }
  