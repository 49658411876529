.logo-img {
    max-width: 100%;
    height: auto;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  
  @media (max-width: 576px) { 
    .logo-img {
        
      max-width: 20%; 
    }
  }

  @media (max-width: 660px) { 
    .logo-img {
        
      max-width: 50%; 
    }
  }
  


